import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";
import { Select } from "clutch/src/Select/Select.jsx";
import { Tag } from "clutch/src/Tag/Tag.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import StatsCards from "@/game-lol/components/ProfileChampionStatsCards.jsx";
import ProfileHeader from "@/game-lol/components/ProfileHeader.jsx";
import {
  QUEUE_SYMBOLS,
  REGION_LIST,
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import profileLinks from "@/game-lol/constants/profile-links.mjs";
import {
  ChampionList,
  Layout,
  PageWrapper,
  Roleblock,
  Roles,
} from "@/game-lol/ProfileChampions.style.jsx";
import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import Static from "@/game-lol/utils/static.mjs";
import QueueSymbol, {
  getQueueFilters,
  QUEUE_SYMBOL_TO_OBJECT,
} from "@/game-lol/utils/symbol-queue.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import useSummonerRanks from "@/game-lol/utils/use-summoner-ranks.mjs";
import {
  findLeagueDataFromQueue,
  getDerivedId,
  getDerivedQueue,
  getLPColor,
  getWinRateColor,
} from "@/game-lol/utils/util.mjs";
import ErrorComponent from "@/shared/ErrorComponent.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { UnknownPlayerHeader } from "@/shared/Profile.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import RiotIDHeaderTitle from "@/shared/RiotIDHeaderTitle.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import SymbolMap from "@/util/symbol-map.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Profile() {
  const { t } = useTranslation();
  const {
    parameters: [region, name, roleStr, champion],
    currentPath,
    searchParams,
  } = useRoute((prev, next) => prev?.currentPath === next.currentPath);
  const state = useSnapshot(readState);
  const [queueGQL, setQueueGQL] = useState(
    searchParams.get("queue") || "RANKED_SOLO_5X5",
  );

  const queue = QueueSymbol(queueGQL);
  const isRanked =
    queue === QUEUE_SYMBOLS.rankedSoloDuo || queue === QUEUE_SYMBOLS.rankedFlex;

  const derivedId = name && getDerivedId(region, name);
  const profile = state.lol.profiles[derivedId];
  const profileError = profile instanceof Error ? profile : null;
  const playerRanks = useSummonerRanks(derivedId);

  const gameName =
    profile?.riotAccount?.gameName || profile?.summonerName || name;
  const tagLine = profile?.riotAccount?.tagLine;

  const iconURL = Static.getProfileIcon(profile?.profileIconId);

  const handleChangeQueue = (selectedQueueGQL) => {
    setQueueGQL(selectedQueueGQL);
    if (selectedQueueGQL === "all") searchParams.delete("queue");
    else searchParams.set("queue", selectedQueueGQL);
    updateRoute(currentPath, searchParams);
  };

  const hideEmptyQueuesFn = (queue) => {
    const leagueData = findLeagueDataFromQueue(playerRanks, queue);
    if (
      ([QUEUE_SYMBOLS.rankedSoloDuo, QUEUE_SYMBOLS.rankedFlex].includes(
        queue,
      ) &&
        !leagueData) ||
      QUEUE_SYMBOL_TO_OBJECT[queue]?.hideFilter
    )
      return false;
    return true;
  };

  const supportedQueues = [
    ...getQueueFilters(t, { filterFn: hideEmptyQueuesFn }),
  ];

  const championsStats =
    state.lol?.playerChampionStats?.[getDerivedQueue(derivedId, queue)];
  const roleSymbol =
    roleStr && queue === QUEUE_SYMBOLS.aram
      ? RoleSymbol("all")
      : RoleSymbol(roleStr);
  const role = roleSymbol && ROLE_SYMBOL_TO_STR[roleSymbol];

  const aggregateStats = useMemo(() => {
    if (!championsStats || championsStats instanceof Error) return null;

    return championsStats.reduce(
      (acc, curr) => {
        const {
          role: roleStr = "",
          basicStats,
          damageStats = {},
          goldStats = {},
          minionStats = {},
          championId,
          gameCount: games,
          gameDuration,
          goldDiffAtLaneEnd,
          lastPlayed,
          queue,
        } = curr;
        if (!basicStats || !games) return acc;
        const role =
          queue === QUEUE_SYMBOLS.aram ? ROLE_SYMBOLS.all : RoleSymbol(roleStr);

        const {
          wins = 0,
          lp = 0,
          kills = 0,
          deaths = 0,
          assists = 0,
          visionScore = 0,
        } = basicStats;
        const { damageToChampions = 0 } = damageStats;
        const { minionsKilledTotal, minionsKilledNeutral } = minionStats;
        const { goldSpent = 0 } = goldStats;

        const lastPlayedDate = new Date(lastPlayed);

        acc.total.wins += wins;
        acc.total.games += games;
        acc.total.lp += lp;
        acc.total.damageToChampions += damageToChampions;
        acc.total.goldSpent += goldSpent;
        acc.total.minutesPlayed += gameDuration / 60;
        acc.total.minionsKilledTotal +=
          minionsKilledTotal + minionsKilledNeutral;
        acc.total.visionScore += visionScore;

        if (!role) return acc;

        const roleList =
          role !== ROLE_SYMBOLS.all
            ? [ROLE_SYMBOLS.all, role]
            : [ROLE_SYMBOLS.all];
        for (const roleSymbol of roleList) {
          if (!acc.roles[roleSymbol])
            acc.roles[roleSymbol] = {
              key: ROLE_SYMBOL_TO_STR[roleSymbol].key,
              title: t(ROLE_SYMBOL_TO_STR[roleSymbol].label),
              symbol: roleSymbol,
              wins: 0,
              games: 0,
              lp: 0,
              goldDiffAtLaneEnd: 0,
              minutesPlayed: 0,
              damageToChampions: 0,
              goldSpent: 0,
              visionScore: 0,
              minionsKilledTotal: 0,
              kills: 0,
              deaths: 0,
              assists: 0,
              champions: {},
              lastPlayed: null,
            };

          acc.roles[roleSymbol].wins += wins;
          acc.roles[roleSymbol].games += games;
          acc.roles[roleSymbol].lp += lp;
          acc.roles[roleSymbol].kills += kills;
          acc.roles[roleSymbol].deaths += deaths;
          acc.roles[roleSymbol].assists += assists;
          acc.roles[roleSymbol].goldDiffAtLaneEnd += goldDiffAtLaneEnd;
          acc.roles[roleSymbol].minutesPlayed += gameDuration / 60;
          acc.roles[roleSymbol].damageToChampions += damageToChampions;
          acc.roles[roleSymbol].goldSpent += goldSpent;
          acc.roles[roleSymbol].visionScore += visionScore;
          acc.roles[roleSymbol].minionsKilledTotal +=
            minionsKilledTotal + minionsKilledNeutral;
          if (lastPlayedDate > acc.roles[roleSymbol].lastPlayed) {
            acc.roles[roleSymbol].lastPlayed = lastPlayedDate;
          }

          acc.roles[roleSymbol].champions[championId] = {};
          acc.roles[roleSymbol].champions[championId].championId = championId;
          acc.roles[roleSymbol].champions[championId].wins = wins;
          acc.roles[roleSymbol].champions[championId].games = games;
          acc.roles[roleSymbol].champions[championId].lp = lp;
          acc.roles[roleSymbol].champions[championId].kills = kills;
          acc.roles[roleSymbol].champions[championId].deaths = deaths;
          acc.roles[roleSymbol].champions[championId].assists = assists;
          acc.roles[roleSymbol].champions[championId].goldDiffAtLaneEnd =
            goldDiffAtLaneEnd;
          acc.roles[roleSymbol].champions[championId].minutesPlayed =
            gameDuration / 60;
          acc.roles[roleSymbol].champions[championId].damageToChampions =
            damageToChampions;
          acc.roles[roleSymbol].champions[championId].goldSpent = goldSpent;
          acc.roles[roleSymbol].champions[championId].visionScore = visionScore;
          acc.roles[roleSymbol].champions[championId].minionsKilledTotal =
            minionsKilledTotal + minionsKilledNeutral;
          acc.roles[roleSymbol].champions[championId].lastPlayed =
            lastPlayedDate;
        }

        return acc;
      },
      {
        total: {
          wins: 0,
          games: 0,
          lp: 0,
          minutesPlayed: 0,
          damageToChampions: 0,
          goldSpent: 0,
          visionScore: 0,
          minionsKilledTotal: 0,
        },
        roles: {},
      },
    );
  }, [t, championsStats]);

  if (profileError) {
    const errorDescription = t(
      "common:error.summonerNotFound",
      "We're unable to find the summoner name in the region specified. Make sure you're not entering your login username, and check the region.",
    );
    return (
      <PageWrapper>
        <ErrorComponent description={errorDescription} />
      </PageWrapper>
    );
  }

  const ROLE_TAGS = {
    0: t("lol:main", "Main"),
    1: t("lol:secondary", "Secondary"),
  };

  const ChampionPoolSelect = (
    <div>
      <Select
        selected={
          supportedQueues.find((q) => q.value === queueGQL)?.value ?? queueGQL
        }
        options={supportedQueues}
        onChange={handleChangeQueue}
      />
    </div>
  );

  if (!championsStats) {
    return (
      <ProfileLayout>
        <ProfileHeader region={region} name={name} />
        <Layout>
          <Card loading style={{ height: 800 }} />
        </Layout>
      </ProfileLayout>
    );
  } else if (!championsStats?.length) {
    return (
      <ProfileLayout>
        <ProfileHeader region={region} name={name} />
        <Layout>
          {ChampionPoolSelect}
          <div className="grid">
            <Card classNameOuter="span-full">
              <ErrorComponent
                description={t(
                  "common:error.statsNotFound",
                  "We're unable to find the summoner stats for that name in the region specified. Make sure you're not entering your login username, and check the region. Enter the same name you use to look yourself up on op.gg or similar sites.",
                )}
              />
            </Card>
          </div>
        </Layout>
      </ProfileLayout>
    );
  } else if (!aggregateStats?.total?.games) {
    return (
      <ProfileLayout>
        <ProfileHeader region={region} name={name} />
        <Layout>
          {ChampionPoolSelect}
          <div className="grid">
            <Card classNameOuter="span-full">
              <ErrorComponent
                description={t(
                  "common:lol.playerQueueStatsNotFound",
                  "We're unable to find champions stats for that name for the selected Queue. Try selecting another queue.",
                )}
              />
            </Card>
          </div>
        </Layout>
      </ProfileLayout>
    );
  }

  if (role && !champion) {
    const RoleIcon = getRoleIcon(roleSymbol);
    const roleStats = aggregateStats.roles[roleSymbol];

    if (!roleStats) {
      return (
        <ProfileLayout>
          <ProfileHeader region={region} name={name} />
          <Layout>
            {ChampionPoolSelect}
            <div className="grid">
              <Card classNameOuter="span-full">
                <ErrorComponent
                  description={t(
                    "common:lol.playerRoleStatsNotFound",
                    "We're unable to find the stats for that name for the selected Role. Try selecting another Role.",
                  )}
                />
              </Card>
            </div>
          </Layout>
        </ProfileLayout>
      );
    }

    return (
      <ProfileLayout>
        <PageHeader
          imageRound
          title={t(ROLE_SYMBOL_TO_STR[roleSymbol].label)}
          icon={<RoleIcon />}
          image={iconURL}
          accentText={profile?.summonerLevel}
          underTitle={
            <div className="flex align-center gap-sp-6">
              <span className="type-body2">
                {t("tft:winsAndLosses", "{{wins}}W - {{losses}}L", {
                  wins: roleStats.wins,
                  losses: roleStats.games - roleStats.wins,
                })}
              </span>
              <span className="type-body2">
                {t("lol:countGame_plural", "{{count}} Games", {
                  count: roleStats.games,
                })}
              </span>
              <span className="type-body2">
                <Trans i18nKey="lol:amountGoldDiff15">
                  {{
                    amount: (
                      roleStats.goldDiffAtLaneEnd / roleStats.games
                    ).toLocaleString(getLocale(), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                  }}
                  <span className="label"> Gold Diff @ 15</span>
                </Trans>
              </span>
              <span className="type-body2">
                <Trans i18nKey="lol:amountKDA">
                  {{
                    kda: (
                      (roleStats.kills + roleStats.assists) /
                      (roleStats.deaths || 1)
                    ).toLocaleString(getLocale(), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                  }}
                  <span className="label"> KDA</span>
                </Trans>
              </span>
            </div>
          }
          links={profileLinks(`/lol/profile/${region}/${name}`, t)}
        />
        <Layout>
          {ChampionPoolSelect}
          <div className="grid">
            <StatsCards stats={roleStats} />
            <Card classNameOuter="span-full">
              <ChampionList>
                {Object.values(roleStats.champions)
                  .sort((a, b) => b.games - a.games || b.wins - a.wins)
                  .map((champion, i) => {
                    const { championId, games = 1, wins, lp } = champion;
                    return (
                      <li key={championId}>
                        <a
                          href={`${currentPath}/${championId}?${searchParams.toString()}`}
                          className="champion"
                          style={{
                            "--champ-color": getWinRateColor(
                              (wins / games) * 100,
                            ),
                          }}
                        >
                          <ChampionImg
                            size={36}
                            championId={championId}
                            lazy={i > 21}
                          />
                          <div className="wins-info">
                            <span className="champ-wr type-caption">
                              {t("lol:championLp.winRate", "{{winRate}}% WR", {
                                winRate: Math.round((wins / games) * 100),
                              })}
                            </span>
                            <span className="champ-wl type-caption">
                              {t(
                                "tft:winsAndLosses",
                                "{{wins}}W - {{losses}}L",
                                {
                                  wins: wins,
                                  losses: games - wins,
                                },
                              )}
                            </span>
                          </div>
                          {isRanked && validLP(lp) && (
                            <span className="champ-lp type-subtitle2">
                              {t("lol:leaguePoints", "{{points}} LP", {
                                points: lp > 0 ? `+${lp}` : (lp ?? 0),
                              })}
                            </span>
                          )}
                        </a>
                      </li>
                    );
                  })}
              </ChampionList>
            </Card>
          </div>
        </Layout>
      </ProfileLayout>
    );
  } else if (role && champion) {
    const championStats = aggregateStats.roles[roleSymbol]?.champions[champion];
    const roleStats = aggregateStats.roles[roleSymbol];

    if (aggregateStats && !championStats) {
      return (
        <ProfileLayout>
          <PageHeader
            imageRound
            title={RiotIDHeaderTitle({ gameName, tagLine })}
            image={Static.getChampionImage(champion)}
            accentText={profile?.summonerLevel}
            links={profileLinks(`/lol/profile/${region}/${name}`, t)}
          />

          <Layout>
            <div>
              <Select
                selected={
                  supportedQueues.find((q) => q.value === queueGQL)?.value ??
                  queueGQL
                }
                options={supportedQueues}
                onChange={handleChangeQueue}
              />
            </div>
            <div className="grid">
              <Card classNameOuter="span-full">
                <ErrorComponent
                  description={t(
                    "common:lol.playerChampionStatsNotFound",
                    "We're unable to find stats for that name for the selected Champion. Try selecting another Champion.",
                  )}
                />
              </Card>
              <Card classNameOuter="span-full">
                <ChampionList>
                  {Object.values(roleStats?.champions || {})
                    .sort((a, b) => b.games - a.games || b.wins - a.wins)
                    .map((champion, i) => {
                      const { championId, games = 1, wins, lp } = champion;
                      const link = `/lol/profile/${region}/${name}/champions/${roleStr}/${championId}?${searchParams.toString()}`;
                      return (
                        <li key={championId}>
                          <a
                            href={link}
                            className="champion"
                            style={{
                              "--champ-color": getWinRateColor(
                                (wins / games) * 100,
                              ),
                            }}
                          >
                            <ChampionImg
                              size={36}
                              championId={championId}
                              lazy={i > 21}
                            />
                            <div className="wins-info">
                              <span className="champ-wr type-caption">
                                {t(
                                  "lol:championLp.winRate",
                                  "{{winRate}}% WR",
                                  {
                                    winRate: Math.round((wins / games) * 100),
                                  },
                                )}
                              </span>
                              <span className="champ-wl type-caption">
                                {t(
                                  "tft:winsAndLosses",
                                  "{{wins}}W - {{losses}}L",
                                  {
                                    wins: wins,
                                    losses: games - wins,
                                  },
                                )}
                              </span>
                            </div>
                            {isRanked && validLP(lp) && (
                              <span className="champ-lp type-subtitle2">
                                {t("lol:leaguePoints", "{{points}} LP", {
                                  points: lp > 0 ? `+${lp}` : (lp ?? 0),
                                })}
                              </span>
                            )}
                          </a>
                        </li>
                      );
                    })}
                </ChampionList>
              </Card>
            </div>
          </Layout>
        </ProfileLayout>
      );
    }

    return (
      <ProfileLayout>
        <PageHeader
          imageRound
          title={t(role.label)}
          image={Static.getChampionImage(champion)}
          accentText={profile?.summonerLevel}
          underTitle={
            <div className="flex align-center gap-sp-6">
              <span className="type-body2">
                {t("tft:winsAndLosses", "{{wins}}W - {{losses}}L", {
                  wins: championStats.wins,
                  losses: championStats.games - championStats.wins,
                })}
              </span>
              <span className="type-body2">
                {t("lol:countGame_plural", "{{count}} Games", {
                  count: championStats.games,
                })}
              </span>
              <span className="type-body2">
                <Trans i18nKey="lol:amountGoldDiff15">
                  {{
                    amount: (
                      championStats.goldDiffAtLaneEnd / championStats.games
                    ).toLocaleString(getLocale(), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                  }}
                  <span className="label"> Gold Diff @ 15</span>
                </Trans>
              </span>
              <span className="type-body2">
                <Trans i18nKey="lol:amountKDA">
                  {{
                    kda: (
                      (championStats.kills + championStats.assists) /
                      (championStats.deaths || 1)
                    ).toLocaleString(getLocale(), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                  }}
                  <span className="label"> KDA</span>
                </Trans>
              </span>
            </div>
          }
          links={profileLinks(`/lol/profile/${region}/${name}`, t)}
        />
        <Layout>
          <div>
            <Select
              selected={
                supportedQueues.find((q) => q.value === queueGQL)?.value ??
                queueGQL
              }
              options={supportedQueues}
              onChange={handleChangeQueue}
            />
          </div>
          <div className="grid">
            <StatsCards stats={championStats} />
          </div>
        </Layout>
      </ProfileLayout>
    );
  }

  return (
    <ProfileLayout>
      {!profileError ? (
        <PageHeader
          imageRound
          title={RiotIDHeaderTitle({ gameName, tagLine })}
          image={iconURL}
          accentText={profile?.summonerLevel}
          links={profileLinks(`/lol/profile/${region}/${name}`, t)}
        />
      ) : (
        <UnknownPlayerHeader />
      )}
      <Layout>
        {ChampionPoolSelect}
        <div className="grid">
          <Roles className="span-full">
            {SymbolMap.values(aggregateStats.roles)
              .filter((role) =>
                queue === QUEUE_SYMBOLS.aram
                  ? true
                  : role.symbol !== ROLE_SYMBOLS.all,
              )
              .sort((a, b) => b.games - a.games || b.wins - a.wins)
              .map((role, index) => (
                <li key={role.title}>
                  <a
                    href={`${currentPath}/${
                      role.key
                    }?${searchParams.toString()}`}
                  >
                    <Roleblock
                      style={{
                        "--role-color": getLPColor(role.lp),
                      }}
                    >
                      <div className="role-left">
                        <div
                          className="role-icon"
                          dangerouslySetInnerHTML={{
                            __html: getRoleIcon(role.key, true),
                          }}
                        />
                        <div className="role-title">
                          <div className="flex align-center gap-sp-4">
                            <h2 className="type-h5">{role.title}</h2>
                            <Tag
                              size="sm"
                              color="var(--shade2)"
                              text={
                                ROLE_TAGS[index] || t("lol:offRole", "Off-Role")
                              }
                            />
                          </div>
                          <div className="role-info">
                            {isRanked && validLP(role.lp) && (
                              <span className="role-lp type-h6">
                                {t("lol:leaguePoints", "{{points}} LP", {
                                  points:
                                    role.lp > 0
                                      ? `+${role.lp}`
                                      : (role.lp ?? 0),
                                })}
                              </span>
                            )}
                            <span className="role-wl type-body2">
                              {t(
                                "tft:winsAndLosses",
                                "{{wins}}W - {{losses}}L",
                                {
                                  wins: role.wins,
                                  losses: role.games - role.wins,
                                },
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <ChampionList className="truncated">
                        {Object.values(role.champions)
                          .sort((a, b) => b.games - a.games || b.wins - a.wins)
                          .slice(0, 4)
                          .map((champion) => {
                            const {
                              wins,
                              lp,
                              championId,
                              games = 1,
                            } = champion;
                            return (
                              <li
                                key={championId}
                                className="champion"
                                style={{
                                  "--champ-color": getWinRateColor(
                                    (wins / games) * 100,
                                  ),
                                }}
                              >
                                <ChampionImg
                                  size={36}
                                  championId={championId}
                                />
                                <div className="wins-info">
                                  <span className="champ-wr type-caption">
                                    {t(
                                      "lol:championLp.winRate",
                                      "{{winRate}}% WR",
                                      {
                                        winRate: Math.round(
                                          (wins / games) * 100,
                                        ),
                                      },
                                    )}
                                  </span>
                                  <span className="champ-wl type-caption">
                                    {t(
                                      "tft:winsAndLosses",
                                      "{{wins}}W - {{losses}}L",
                                      {
                                        wins: wins,
                                        losses: games - wins,
                                      },
                                    )}
                                  </span>
                                </div>
                                {isRanked && validLP(lp) && (
                                  <span className="champ-lp type-subtitle2">
                                    {t("lol:leaguePoints", "{{points}} LP", {
                                      points: lp > 0 ? `+${lp}` : (lp ?? 0),
                                    })}
                                  </span>
                                )}
                              </li>
                            );
                          })}
                      </ChampionList>
                    </Roleblock>
                  </a>
                </li>
              ))}
          </Roles>
        </div>
      </Layout>
    </ProfileLayout>
  );
}

function validLP(lp) {
  return lp > 0 || lp < 0;
}

export function meta([regionId, name, _roleStr, _champion]) {
  const region = REGION_LIST.find((region) => region.key === regionId);
  const userName = name.replace("-", " #");

  return {
    title: [
      "lol:meta.profile.title",
      "{{userName}}'s {{regionName}} League of Legends Performance Overview",
      { userName, regionName: region?.name ?? regionId },
    ],
    description: [
      "lol:meta.profile.description",
      "View {{userName}}'s League of Legends profile and see how they perform.",
      { userName, regionName: region?.name ?? regionId },
    ],
  };
}

export default Profile;
